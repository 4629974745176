<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img fluid :src="logo" alt="Login V2" />
        <h2 class="brand-text text-primary ml-1 mt-2">
          {{ appTitle }}
        </h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="mx-auto">
          <b-card-title title-tag="h4" class="text-center mb-1"> Hoşgeldiniz </b-card-title>
          <b-card-text class="text-center mb-2"> Lütfen E Posta adresinizi giriniz. </b-card-text>

          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" class="p-2" autocomplete="off">
              <b-row>
                <b-col cols="12">
                  <validation-provider name="E Mail" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="E Mail">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        v-model="form.email"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col> </b-col>
                <b-col>
                  <div class="d-flex justify-content-end">
                    <b-link :to="{ name: 'login' }">
                      <small>Giriş Yap</small>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
              <b-button squared type="submit" size="lg" variant="primary" block :disabled="show">
                <b-spinner small v-show="show" />
                Gönder
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import CryptoJS from 'crypto-js';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import 'vue-toastification/dist/index.css';
export default defineComponent({
  components: {},
  setup() {
    const expo = {};
    const cookies = useCookies();
    const { router } = useRouter();
    const toast = useToast();

    expo.appTitle = 'Interyum System v 2.0';
    expo.logo = require('@/assets/images/logo/logo-icon.png');
    expo.imgUrl = require('@/assets/images/auth/login-v2.svg');

    expo.passwordFieldType = ref('password');
    expo.show = ref(false);
    expo.form = ref({
      email: null,
      sifre: null,
      rememberme: false,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.passwordToggleIcon = computed(() => {
      return expo.passwordFieldType.value === 'password' ? 'eye-slash' : 'eye';
    });

    expo.togglePasswordVisibility = () => {
      expo.passwordFieldType.value = expo.passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    if (cookies.get('panelrememberme')) {
      const { VUE_APP_JWT_SECRET_KEY } = process.env;
      let bytes = CryptoJS.AES.decrypt(cookies.get('sifre'), VUE_APP_JWT_SECRET_KEY);
      let pass = bytes.toString(CryptoJS.enc.Utf8);
      expo.form.value.email = cookies.get('email');
      expo.form.value.sifre = pass;
      expo.form.value.rememberme = true;
    }

    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('login', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            if (expo.form.value.rememberme === true) {
              const { VUE_APP_JWT_SECRET_KEY } = process.env;
              let password = CryptoJS.AES.encrypt(expo.form.value.sifre, VUE_APP_JWT_SECRET_KEY).toString();
              cookies.set('panelrememberme', expo.form.value.rememberme);
              cookies.set('email', expo.form.value.email);
              cookies.set('sifre', password);
            } else {
              cookies.remove('panelrememberme');
              cookies.remove('email');
              cookies.remove('sifre');
            }
            router.replace('/').then(() => {
              toast.success('Hoşgeldiniz...', { position: 'bottom-left' });
            });
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (!err.data.success) {
            console.log(err);
            toast.error('Kullanıcı adı veya şifrenizi kontrol ediniz.');
            expo.show.value = false;
          }
        });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
